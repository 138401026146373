import React from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ImageListItemBar } from "@mui/material";

let images = [
  {
    img: 1,
    title: "Open Showjumping Championship",
    subtitle: "07/07-08/07"
  },
  {
    img: 2,
    title: "Open Showjumping Championship",
    subtitle: "07/07-08/07"
  },
  {
    img: 3,
    title: "Memorial Tournament",
    subtitle: "Hovik Hayrapetyan’s memory 19/03/2023"
  },
  {
    img: 4,
    title: "Armenian Open Republican",
    subtitle: "Showjumping Tournament 04/11-05/11"
  },
  {
    img: 5,
    title: "Armenian Open Republican",
    subtitle: "Showjumping Tournament 04/11-05/11"
  },
  {
    img: 6,
    title: "Armenian Showjumping Republican",
    subtitle: "National Championship  27/05-28/05"
  },
]

export const Events = (props) => {
  return (
    <div id="events" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Events</h2>
          <p>
            The Equestrian Federation of Armenia is involved in various equestrian events and activities, with a particular emphasis on fostering the sport in the country.
          </p>
        </div>

        <ImageList sx={{ width: "100%", height: "auto" }} cols={3} rowHeight={164}>
          {images.map((item, index) => (
            <ImageListItem key={"imageEvent" + index} style={{ height: "300px" }}>
              <div style={{
                height: "300px",
                background: "transparent no-repeat center",
                backgroundSize: "cover",
                backgroundImage: `url(${require(`../assets/images/events/${item.img}.jpg`)})`

              }}>
              </div>
              <ImageListItemBar
                style={{ fontSize: "200%" }}
                title={item.title}
                subtitle={item.subtitle}
              />
            </ImageListItem>

          ))}
        </ImageList>
      </div>
    </div >
  );
};
