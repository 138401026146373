import React from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { ImageListItemBar } from "@mui/material";

let images = [
  {
    "img": 1,
    "title": "Hovik Hayapetyan Equestrian centre"
  },
  {
    "img": 2,
    "title": "Sil Club"
  },
  {
    "img": 3,
    "title": "Multi Group Club"
  },
  {
    "img": 4,
    "title": "Mirage Equestrian Centre"
  },
  {
    "img": 5,
    "title": "Biayna Club"
  },
  {
    "img": 6,
    "title": "Ayrudzi Riding Club"
  }
]



export const Clubs = (props) => {
  return (
    <div id="clubs" className="text-center" style={{ marginTop: "20px", marginBottom: "20px" }}>
      <div className="container">
        <div className="section-title">
          <h2>Clubs</h2>
          <p>
            In Armenia, one of the most prominent equestrian centers is the Hovik Hayrapetyan Equestrian Center, located in Yerevan. This center is a key location for equestrian activities and hosts various competitions.
            Additionally, there are other horse-riding centers and clubs in Armenia.
          </p>
        </div>

        <ImageList sx={{ width: "100%", height: "auto" }} cols={3} rowHeight={164}>
          {images.map((item, index) => (
            <ImageListItem key={"imageEvent" + index} style={{ height: "300px" }}>
              <div style={{
                height: "300px",
                background: "transparent no-repeat center",
                backgroundSize: "cover",
                backgroundImage: `url(${require(`../assets/images/clubs/${item.img}.jpg`)})`

              }}>
              </div>
              <ImageListItemBar
                style={{ fontSize: "200%" }}
                title={item.title}
                subtitle={item.subtitle}
              />
            </ImageListItem>

          ))}
        </ImageList>
      </div>
    </div >
  );
};

