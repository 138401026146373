import React from "react";

export const Team = (props) => {
  return (
    <div id="executiveboard" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Executive Board</h2>
          <p>
            The Equestrian Federation of Armenia, also known as the Federation of Equestrian Sport of Armenia, serves as the regulating body for equestrian sports in Armenia. It operates under the governance of the Armenian Olympic Committee.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                <div className="thumbnail">
                  {" "}
                  <img src={d.img} alt="..." className="team-img" />
                  <div className="caption">
                    <h4>{d.name}</h4>
                    <p>{d.job}</p>
                  </div>
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
