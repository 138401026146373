import { Image } from "./image";
import React from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

let images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>

        <ImageList sx={{ width: "100%", height: "auto" }} cols={3} rowHeight={164}>
          {images.map((item) => (
            <ImageListItem key={"image" + item.img} style={{ height: "500px" }}>
              <div style={{
                height: "500px",
                background: "transparent no-repeat center",
                backgroundSize: "cover",
                backgroundImage: `url(${require(`../assets/images/gallery/${item}.jpg`)})`
              }}>
              </div>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </div >
  );
};
